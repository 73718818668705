
import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { Link } from "gatsby"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"

const StyledContainer = styled(Container)`
  max-width: 1200px;
  padding: 0;
  margin: 0 auto;
`

const StyledRecognition = styled.section`
	padding: 28px 0;

	${breakpoint.medium`
		padding: 54px 0;
	`}

	.titleTop {
		margin-bottom: 30px;
		text-align: center;

		h2 {
			margin: 60px 0;
		}	
 	}

	.awards_row {
		display: flex;
		width: 100%;
		flex-wrap: wrap;
		column-gap: 0;
		columns: 2;
		margin: 10px auto;
		justify-content: center;
		align-items: center;
		padding: 0 0 50px 0;
		padding-inline: 10px;

		  ${breakpoint.xs`
				flex-wrap: nowrap;
		  `}

		  ${breakpoint.medium`
				padding-inline: 0;
		  `}

		.image_column { 
			width: 260px;
			margin: 0 auto;
			align-self: center;
			
		  ${breakpoint.xs`
			width: 230px;
		  `}

		  a:focus {
			display: block;
		  }
		}
		
		 .text_column { 
			width: 90%;
			border-left: 0;
			padding-left: 0;
			margin: 40px 10px 10px 10px;
			text-align: center;
			
		  ${breakpoint.xs`
				width: 75%;
				margin: 10px;
				border-left: 1px ${colors.charcoal} solid;
				padding-left: 30px;
				text-align: left;
		  `}

		}

	h3 {
		font-size: larger;
		margin-bottom: 15px;
	}

	p {
		padding-right: 0;
		
		  ${breakpoint.medium`
		  		padding-right: 20px;
		  `}
	}

	.award_image, .larger_logo { 
			width: 60%;
			margin-inline: 25px;

		  ${breakpoint.medium`
		  		margin-top: 0;
		  		max-width: 150px;
				width: 80%;
				height: auto;
		  `}
		} 
		
	.larger_logo	{ 
			width: 95%;

		  ${breakpoint.small`
		  		max-width: 200px;
		  `}

		  ${breakpoint.medium`
		  		max-width: 250px;
		  `}
		} 	
	
	.gatsby-image-wrapper {
		margin: auto;
	}	
		
	ul {
		list-style: none;
		font-size: 92%;
		line-height: 1.2rem;
		padding: 0;
		
		  ${breakpoint.xs`
		  		list-style: disc;
				padding: 0.3rem 4rem 1rem 2rem;
				line-height: 1.3rem;
		  `}
		  ${breakpoint.small`
				padding: 0.3rem 8rem 1rem 3rem;
				line-height: 1.5rem;
		  `}
	}

	li { 
		padding-block: 0.5rem;
		  ${breakpoint.xs`
		  		padding-block: 0;
				padding-bottom: 0;
		  `}
		  ${breakpoint.medium`
				padding-bottom: 0.5rem;
		  `}
	}	
	
	b {
		font-weight: 600;
	}
		
		a {
			color: ${colors.orange};

			&:hover {
			  color: ${colors.charcoal};
			}
		}	
	
	.readMorePara {
		padding-top: 10px;
	}
	
	.readMore {
		font-size: 85%;
		font-weight: 600;
	}
		 
	.readMore::after {
		content: "→";
		padding-left: 4px;
		color: ${colors.orange};
	}

	.readMore:hover::after {
		content: "→";
		color: ${colors.charcoal};
	}
		

	}
`

const Recognition = () => {

  return (
    <StyledRecognition>
      <StyledContainer>
        <div className="titleTop" data-aos="fade-up"  data-aos-once="false">
          <h2>Awards</h2>
        </div>

		<div className="awards_row" data-aos="fade-up" data-aos-once="false">
			<div className="image_column">
			<a href="https://www.idsa.org/awards/idea/lifestyle-accessories/visby-medical-covid-19-test" target="_blank" rel="noreferrer noopener">
				<StaticImage
					src="../../../assets/images/awards/idea-award.png"
					width={250}
					alt="IDSA.org logo"
					className="award_image larger_logo"
				/>
			</a>
			  </div>
			<div className="text_column">
				<h3>International Design Excellence Awards</h3>
				<p><b>Category:</b> Lifestyle &amp; Accessories &gt; Silver Award &gt; Visby Medical COVID-19&nbsp;Test</p>
				<p><b>Organizer:</b> <a href="https://www.IDSA.org/" target="_blank" rel="noreferrer noopener">IDSA.org</a> (Industrial Designers Society of America)</p>
			  </div>
		 </div>
		<div className="awards_row" data-aos="fade-up" data-aos-once="false">
			<div className="image_column">
				<a href="https://www.fastcompany.com/90667022/general-excellence-innovation-by-design-2021" target="_blank" rel="noreferrer noopener">
					<StaticImage
						src="../../../assets/images/awards/innovation-award.jpg"
						width={250}
						alt="Innovation logo"
						className="award_image larger_logo"
					/>
				</a>
			  </div>
			<div className="text_column">
				<h3>Fast Company’s 2021 Innovation by Design Awards</h3>
				<p><b>Visby Medical COVID-19 Test:</b></p>
				<ul>
					<li><a href="https://www.fastcompany.com/90667022/general-excellence-innovation-by-design-2021" target="_blank" rel="noreferrer noopener">General Excellence category</a> &gt; Innovation in Diagnostic Design</li>
					<li><a href="https://www.fastcompany.com/90667024/health-innovation-by-design-2021" target="_blank" rel="noreferrer noopener">Health category</a> &gt; Innovation in Diagnostic Design</li>
				</ul>
			  </div>
		</div>

        <div className="titleTop" data-aos="fade-up" data-aos-once="false">
          <h2>Grants</h2>
        </div>
        
		<div className="awards_row"  data-aos="fade-up" data-aos-once="false">
			<div className="image_column">
				<Link to="/news/press-release-visby-medical-speeds-development-of-a-rapid-flu-covid-pcr-test-designed-for-at-home-use-with-barda-funding/">
					<StaticImage
						src="../../../assets/images/awards/barda-logo.png"
						layout="fullWidth"
						alt="BARDA logo"
						className="award_image"
					/>
				</Link>
			  </div>
			<div className="text_column">
				<h3>Up to $48.7 MM BARDA Funding</h3>
				<p>Biomedical Advanced Research and Development Authority (BARDA), within the Office of the Assistant Secretary for Preparedness and Response in the U.S. Department of Health and Human Services, 
				power development of a rapid Flu-COVID PCR test designed for at-home use. </p>
				<p className="readMorePara"><Link to="/news/press-release-visby-medical-speeds-development-of-a-rapid-flu-covid-pcr-test-designed-for-at-home-use-with-barda-funding/" className="readMore">See original press&nbsp;release</Link></p>
			  </div>
		 </div>
		<div className="awards_row" data-aos="fade-up" data-aos-once="false">
			<div className="image_column">
			 	<Link to="/news/visby-medicals-personal-pcr-receives-funding-from-national-institute-of-health-accepted-into-radx-program/">
					<StaticImage
						src="../../../assets/images/awards/nih-logo.png"
						layout="fullWidth"
						alt="NIH logo"
						className="award_image"
					/>
				</Link>
			  </div>
			<div className="text_column">
				<h3>$10.0 MM NIH RADx Funding</h3>
				<p>Phase 2 funding was awarded to Visby Medical from the National Institutes of Health (NIH) as a part of the Rapid Acceleration of Diagnostics (RADx) initiative. 
				The RADx initiative aims to combat SARS-CoV-2 and increase national testing capacity for COVID-19. </p>
				<p className="readMorePara"><Link to="/news/visby-medicals-personal-pcr-receives-funding-from-national-institute-of-health-accepted-into-radx-program/" className="readMore">See original press&nbsp;release</Link></p>
			  </div>
		 </div>
		<div className="awards_row" data-aos="fade-up" data-aos-once="false">
			<div className="image_column">
				<Link to="/news/press-release-rapid-diagnostic-for-gonorrhea-wins-19-million-federal-prize-competition-to-combat-antibiotic-resistance/">
					<StaticImage
						src="../../../assets/images/awards/hhs-logo.png"
						layout="fullWidth"
						alt="NIH/BARDA/HHS logo"
						className="award_image"
					/>
				</Link>
			  </div>
			<div className="text_column">
				<h3>$19.0 million awarded by NIH/BARDA/HHS to combat antibiotic resistance</h3>
				<p>Visby Medical, Inc., was awarded $19 million as a prize for its winning diagnostic test capable of accurately and reliably detecting the microorganism that causes gonorrhea 
				and rapidly determining in under 30 minutes if the microorganism is susceptible to a single-dose antibiotic. </p>
				<p className="readMorePara"><Link to="/news/press-release-rapid-diagnostic-for-gonorrhea-wins-19-million-federal-prize-competition-to-combat-antibiotic-resistance/" className="readMore">See original press&nbsp;release</Link></p>
				<p><Link to="/sexual-health-test/" className="readMore">Learn more about the Sexual Health Test</Link></p>
			  </div>
		</div>
        </StyledContainer>
   </StyledRecognition>
  )
}
export default Recognition
